import React, { Component } from "react"

class Verzeichnis extends Component {
  constructor(props) {
    super(props)
    this.props = {
    }
  }

  render() {
    const noteItems = this.props.Inhaltsverzeichnis.map(note => (
      <li key={note.seite}>
        {note.seite} {note.name}
      </li>
    ))
    return <ul>{noteItems}</ul>
  }
}

class VerzeichnisBackBuch1 extends Component {
  render() {
    return <Verzeichnis Inhaltsverzeichnis={InhaltsverzeichnisBackBuch1} />
  }
}

class VerzeichnisEinweckBuch extends Component {
  render() {
    return <Verzeichnis Inhaltsverzeichnis={InhaltsverzeichnisEinweck} />
  }
}

const InhaltsverzeichnisBackBuch1 = [
  {
    seite: "07",
    name: "Vorwort",
  },
  {
    seite: "08",
    name: "Ananastorte",
  },
  {
    seite: "09",
    name: "Apfeltorte",
  },
  {
    seite: "10",
    name: "BirneHelene",
  },
  {
    seite: "11",
    name: "Brombeer-Käsekuchen",
  },
  {
    seite: "12",
    name: "Cappuccinotorte",
  },
  {
    seite: "13",
    name: "Charlottetorte",
  },
  {
    seite: "14",
    name: "Eierlikör-Moccatorte",
  },
  {
    seite: "15",
    name: "Erdbeer-Sahnetorte",
  },
  {
    seite: "16",
    name: "Himbeertorte",
  },
  {
    seite: "17",
    name: "HimmlischeTorte",
  },
  {
    seite: "18",
    name: "Malakoff-Orangentorte",
  },
  {
    seite: "19",
    name: "Mocca-Eierlikörtorte",
  },
  {
    seite: "20",
    name: "Nuss-Sahnetorte",
  },
  {
    seite: "21",
    name: "Pistazien-Edbeertorte",
  },
  {
    seite: "22",
    name: "Prinzregententorte",
  },
  {
    seite: "23",
    name: "Quarktorte",
  },
  {
    seite: "24",
    name: "Rhabarber-Baisertorte",
  },
  {
    seite: "25",
    name: "Rotkäppchentorte",
  },
  {
    seite: "26",
    name: "Sauerkirsch-Cremetorte",
  },
  {
    seite: "27",
    name: "Schachbrett-Torte",
  },
  {
    seite: "28",
    name: "Schlupfkuchen",
  },
  {
    seite: "29",
    name: "Schoko-Kuppeltorte",
  },
  {
    seite: "30",
    name: "Stachelbeertorte",
  },
  {
    seite: "31",
    name: "Stricknadeltorte",
  },
  {
    seite: "32",
    name: "Wickeltorte",
  },
  {
    seite: "33",
    name: "Schoko-Biskuitrolle",
  },
  {
    seite: "34",
    name: "Fantaschnitten",
  },
  {
    seite: "35",
    name: "Johannisbeerschnitten",
  },
  {
    seite: "36",
    name: "Lambadaschnitten",
  },
  {
    seite: "37",
    name: "Zitrone-Joghurtschnitten",
  },
  {
    seite: "38",
    name: "Cremeschnitten",
  },
  {
    seite: "39",
    name: "Frühlingsschnitten",
  },
  {
    seite: "40",
    name: "Ibischnitten",
  },
  {
    seite: "41",
    name: "Liaschnitten",
  },
  {
    seite: "42",
    name: "Löffelbiskuitschnitten",
  },
  {
    seite: "43",
    name: "Karamellschnitten",
  },
  {
    seite: "44",
    name: "Londonschnitten",
  },
  {
    seite: "45",
    name: "Ninaschnitten",
  },
  {
    seite: "46",
    name: "Nougatschnitten",
  },
  {
    seite: "47",
    name: "Schokoladenschnitten",
  },
  {
    seite: "48",
    name: "Spanische Schnitten",
  },
  {
    seite: "49",
    name: "Greta-Garbo-Schnitten",
  },
  {
    seite: "50",
    name: "Harlekinschnitten",
  },
  {
    seite: "51",
    name: "Zitronenschnitten",
  },
  {
    seite: "52",
    name: "Zwetschgendatschi",
  },
  {
    seite: "53",
    name: "Zwetschgen-Hanklich",
  },
  {
    seite: "54",
    name: "Zwetschgenkuchen",
  },
  {
    seite: "55",
    name: "Gedeckter Apfelkuchen",
  },
  {
    seite: "56",
    name: "Nussecken",
  },
  {
    seite: "57",
    name: "Grammelpogatschen",
  },
  {
    seite: "58",
    name: "Kekswurst",
  },
  {
    seite: "59",
    name: "Rumkugeln",
  },
  {
    seite: "60",
    name: "Hanklich (Eierkuchen)",
  },
  {
    seite: "61",
    name: "Hanklich mit Rahmguß",
  },
  {
    seite: "62",
    name: "Nuss-Strudel",
  },
  {
    seite: "63",
    name: "Krapfen",
  },
  {
    seite: "64",
    name: "Eierlikörkuchen",
  },
  {
    seite: "65",
    name: "Gewürzkuchen",
  },
  {
    seite: "66",
    name: "Nusskuchen",
  },
  {
    seite: "67",
    name: "Rotweinkuchen",
  },
  {
    seite: "68",
    name: "Sandkuchen",
  },
  {
    seite: "69",
    name: "Zebrakuchen",
  },
  {
    seite: "70",
    name: "Quarkstollen",
  },
  {
    seite: "71",
    name: "Elisenlebkuchen",
  },
  {
    seite: "72",
    name: "Vanillekekse",
  },
  {
    seite: "73",
    name: "Terrassen",
  },
  {
    seite: "74",
    name: "Kosakentaler",
  },
  {
    seite: "75",
    name: "Marzipantaler",
  },
  {
    seite: "76",
    name: "Mandelplätzchen",
  },
  {
    seite: "77",
    name: "Makronen",
  },
  {
    seite: "78",
    name: "Orangezungen",
  },
  {
    seite: "79",
    name: "Nuss-Schiffchen",
  },
  {
    seite: "80",
    name: "Pfauenauge",
  },
  {
    seite: "81",
    name: "Ischler",
  },
  {
    seite: "82",
    name: "Non plus ultra",
  },
  {
    seite: "83",
    name: "Rumherzen",
  },
  {
    seite: "84",
    name: "Schokohäppchen",
  },
  {
    seite: "85",
    name: "Spitzbuben",
  },
  {
    seite: "86",
    name: "Spritzgebäck",
  },
  {
    seite: "87",
    name: "Vanillehörnchen",
  },
  {
    seite: "88",
    name: "Osterlamm (Osterhase)",
  },
]

const InhaltsverzeichnisEinweck = [
  {
    seite: "05",
    name: "Vorwort",
  },
  {
    seite: "07",
    name: "Marmeladen und Gelees",
  },
  {
    seite: "08",
    name: "Apfelmus",
  },
  {
    seite: "09",
    name: "Apfelgelee",
  },
  {
    seite: "10",
    name: "Aprikosenmarmelade",
  },
  {
    seite: "11",
    name: "Aroniamarmelade (Apfelbeere)",
  },
  {
    seite: "12",
    name: "Birnenmarmelade",
  },
  {
    seite: "13",
    name: "Blaubeermarmelade (Heseiteelbeere)",
  },
  {
    seite: "14",
    name: "Brombeermarmelade",
  },
  {
    seite: "15",
    name: "Dreiklangmarmelade",
  },
  {
    seite: "16",
    name: "Erdbeermarmelade",
  },
  {
    seite: "17",
    name: "Erdbeer-Rhabarber-Marmelade",
  },
  {
    seite: "18",
    name: "Hagebuttenmarmelade",
  },
  {
    seite: "19",
    name: "Himbeermarmelade",
  },
  {
    seite: "20",
    name: "Johannisbeergelee",
  },
  {
    seite: "21",
    name: "Kirschmarmelade (Sauerkirsche)",
  },
  {
    seite: "22",
    name: "Preiselbeermarmelade",
  },
  {
    seite: "23",
    name: "Quittenmarmelade",
  },
  {
    seite: "24",
    name: "Quittengelee",
  },
  {
    seite: "25",
    name: "Zwetschgenmarmelade (Pflaumen)",
  },
  {
    seite: "26",
    name: "Quittenkäse",
  },
  {
    seite: "27",
    name: "Kompotte und Sirups",
  },
  {
    seite: "28",
    name: "Apfelkompott",
  },
  {
    seite: "29",
    name: "Aprikosen- oder Pfirsichkompott",
  },
  {
    seite: "30",
    name: "Birnenkompott",
  },
  {
    seite: "31",
    name: "Essigpflaumen",
  },
  {
    seite: "32",
    name: "Frischer Erdbeer-Rhabarber-Kompott",
  },
  {
    seite: "33",
    name: "Kirschkompott",
  },
  {
    seite: "34",
    name: "Kürbiskompott",
  },
  {
    seite: "35",
    name: "Quittenkompott",
  },
  {
    seite: "36",
    name: "Sauerkirschenkompott (Weichseln)",
  },
  {
    seite: "37",
    name: "Stachelbeerkompott",
  },
  {
    seite: "38",
    name: "Weintraubenkompott",
  },
  {
    seite: "39",
    name: "Zwetschgen in Portwein",
  },
  {
    seite: "40",
    name: "Eingelegte Zwetschgen für Kuchen",
  },
  {
    seite: "41",
    name: "Zwetschgenkompott",
  },
  {
    seite: "42",
    name: "Himbeersirup",
  },
  {
    seite: "43",
    name: "Holundersaft",
  },
  {
    seite: "44",
    name: "Holundersirup",
  },
  {
    seite: "45",
    name: "LikörundPunsch",
  },
  {
    seite: "46",
    name: "Weichsellikör",
  },
  {
    seite: "47",
    name: "Punsch",
  },
  {
    seite: "48",
    name: "Alkoholfreier Punsch",
  },
  {
    seite: "49",
    name: "Eingewecktes Gemüse",
  },
  {
    seite: "50",
    name: "Salzgurken",
  },
  {
    seite: "51",
    name: "Essiggurken",
  },
  {
    seite: "52",
    name: "Grüne Tomaten",
  },
  {
    seite: "53",
    name: "Peperoni",
  },
  {
    seite: "54",
    name: "Tomatenpaprika",
  },
  {
    seite: "55",
    name: "Sauerkrautsalat",
  },
  {
    seite: "56",
    name: "Sauerkrauttopf",
  },
  {
    seite: "57",
    name: "Pickles",
  },
  {
    seite: "58",
    name: "Rote Beete",
  },
  {
    seite: "59",
    name: "Grüne Bohnen mit Tomaten",
  },
  {
    seite: "60",
    name: "Zuchini süßsauer",
  },
  {
    seite: "61",
    name: "Sakuska",
  },
  {
    seite: "62",
    name: "Tomatenmark, siebenbürgische Art",
  },
  {
    seite: "63",
    name: "Bärlauchpaste",
  },
  {
    seite: "64",
    name: "Estragon eingelegt",
  },
]

export { VerzeichnisBackBuch1, VerzeichnisEinweckBuch }
